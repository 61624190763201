export const getReportStatusLabel = status => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'in_review':
      return 'In review'
    case 'resolved':
      return 'Resolved'
    case 'pending_dispute':
      return 'Dispute pending'
    case 'in_review_dispute':
      return 'Dispute in review'
    case 'resolved_dispute':
      return 'Dispute resolved'
    default:
      return ''
  }
}
