<template>
  <div>
    <Loading v-if="loading" />
    <div>
      <v-card
        class="mx-auto mb-5"
      >
        <v-card-title>
          {{ cardTitle }}
          <v-btn
              v-if="refreshEvent"
              :right="true" 
              :absolute="true"
              text
              icon
              @click="refresh()"
            >
              <v-icon>{{ icons.mdiReload }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="refreshInProgress || !$store.getters[hasLoadedGetter](page)"
            loading-text="Loading reports..."
            :headers="headers"
            :items="reports"
            :items-per-page="10"
            :hide-default-footer="true"
            :disable-filtering="true"
            :disable-sort="true"
            :disable-pagination="true"
            item-key="report_id"
            show-expand
            @item-expanded="newCommentText = ''"
          >
            <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
              <v-icon
                v-if="allowExpand"
                :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                @click.stop="expand(!isExpanded)"
              >
                $expand
              </v-icon>
            </template>
            <template #[`item.timestamp`]="{item}">
              {{ unixToFormattedTimeMonthNumeric(item.timestamp) }}
            </template>
            <template #[`item.report_status`]="{item}">
              {{ getReportStatusLabel(item.report_status) }}
            </template>
            <template #[`item.reportee`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column">
                  <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.reportee_nickname }}</span>
                  <small v-if="showId">{{ item.reportee_id }}</small>
                </div>
                <v-btn
                  class="ma-2"
                  text
                  :href="'/profile/' + item.reportee_id"
                  target="_blank"
                  icon
                  color="red lighten-2"
                >
                  <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.reporter`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column">
                  <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.reporter_nickname }}</span>
                  <small v-if="showId">{{ item.reporter_id }}</small>
                </div>
                <v-btn
                  class="ma-2"
                  text
                  :href="'/profile/' + item.reporter_id"
                  target="_blank"
                  icon
                  color="red lighten-2"
                >
                  <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.assign`]="{item}">
              <v-btn
                color="success"
                dark
                @click="assignToMe(item)"
              >
                Assign to me
              </v-btn>
            </template>
            <template #expanded-item="{ item }">
              <td
                :colspan="8"
                class=""
              >
                <v-card color="">
                  <v-card-title>
                    <span
                      v-if="showId"
                      class="report-id-badge"
                    >Report ID {{ item.report_id }}</span>

                    <v-btn
                      color="info"
                      small
                      class="mr-3"
                      :href="'https://www.faceit.com/en/csgo/room/' + item.match_id"
                      target="_blank"
                    >
                      Match room
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog
                      v-if="!item.resolution && allowManagement"
                      v-model="isResolveDialogVisible"
                      persistent
                      max-width="600px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          dark
                          class="mr-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Mark as resolved
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Resolve case</span>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col
                              class="mb-2"
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <template>
                                <div class="demo-space-x d-flex flex-wrap">
                                  <v-checkbox
                                    v-model="isPunishmentIssued"
                                    label="Punishment issued"
                                    color="success"
                                    value="success"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template>
                            </v-col>
                            <v-col
                              v-if="isPunishmentIssued"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-select
                                v-model="offense"
                                :items="['Anti-Competitive Gameplay', 'Community Misconduct', 'Griefing', 'Failure to Communicate', 'Death Threats', 'Friendly Fire', 'Harassment', 'Inappropriate Language/Icongraphy', 'Intoxication', 'Match Abuse', 'Pause Abuse', 'Support Abuse', 'Throwing', 'Trolling Toxicity', 'Cheating/Exploits','Leaver',]"
                                label="Offense"
                                outlined
                              ></v-select>
                            </v-col>
                            <v-col
                              v-if="isPunishmentIssued"
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <v-select
                                v-model="banLength"
                                :items="['15 minutes', '20 minutes', '30 minutes','1 hour', '2 hours', '3 hours', '6 hours','12 hours','1 Day', '2 Days', '3 Days', '7 Days',]"
                                label="Queue ban length"
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>

                          <v-textarea
                            :counter="350"
                            :rows="4"
                            :rules="commentRules"
                            v-model="resolveComments"
                            no-resize
                            outlined
                            label="Additional comments"
                          ></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            outlined
                            @click="isResolveDialogVisible = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            class="text-right"
                            color="success"
                            @click="resolveReport(item)"
                            :disabled="resolveComments.length > 350 || (isPunishmentIssued && (!offense || !banLength))"
                            dark
                          >
                            Resolve
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-if="!item.resolution"
                      v-model="isCommentDialogVisible"
                      persistent
                      max-width="600px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="dark"
                          dark
                          class="mr-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New comment
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="headline">Post a new comment</span>
                        </v-card-title>
                        <v-card-text>
                          <v-textarea
                            v-model="newCommentText"
                            :rules="commentRules"
                            :counter="350"
                            :rows="4"
                            no-resize
                            outlined
                            label="Comment"
                          ></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            outlined
                            @click="isCommentDialogVisible = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            class="text-right"
                            color="dark"
                            :disabled="newCommentText.length <= 0 || newCommentText.length > 350"
                            dark
                            @click="postComment(item)"
                          >
                            Post comment
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      v-if="!item.resolution && allowManagement"
                      color="dark"
                      dark
                      class="mr-3"
                      @click="unassign(item)"
                    >
                      Unassign
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <v-card-text>
                      <v-timeline
                        dense
                        class="timeline-custom-dense timeline-custom-dots card-content"
                      >
                        <v-timeline-item
                          v-if="item.resolution"
                          small
                          color="primary"
                        >
                          <v-card color="darkbtn3">
                            <v-card-title>
                              <span class="text-base white--text">Resolution</span>
                              <v-spacer></v-spacer>
                              <small class="text-xs white--text text-no-wrap">{{ unixToFormattedTimeMonthNumeric(item.resolution.timestamp) }}</small>
                            </v-card-title>
                            <v-card-text class="pt-4">
                              <p>
                                {{ item.resolution.isPunishmentIssued ? 'Punishment reason: ' + item.resolution.offense : 'No punishment was issued!' }}
                              </p>
                              <p>
                                {{ item.resolution.isPunishmentIssued ? 'Punishment length: ' + item.resolution.queueBanLength : '' }}
                              </p>
                              <p v-if="item.resolution.comment.length > 0">
                                {{ item.resolution.comment }}
                              </p>

                              <!-- Divider -->
                              <v-divider class="my-4"></v-divider>

                              <!-- Person -->
                              <div class="d-flex justify-space-between align-center">
                                <!-- Avatar & Personal Info -->
                                <span class="d-flex align-bottom mt-2">
                                  <!-- <v-avatar
                                        size="40"
                                        class="me-2"
                                      >
                                        <v-img src="@/assets/images/avatars/2.png"></v-img>
                                      </v-avatar> -->
                                  <div class="d-flex flex-column">
                                    <p class="text--primary font-weight-semibold mb-0">{{ item.resolution.author_nickname }} </p>
                                    <span> {{ item.resolution.author_role }} </span>
                                  </div>
                                </span>

                                <!-- Person Actions -->
                                <div>
                                  <v-icon class="me-2">
                                    {{ icons.mdiMessageTextOutline }}
                                  </v-icon>
                                  <v-icon>{{ icons.mdiPhone }}</v-icon>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                        <v-timeline-item
                          v-for="(comment, index) in item.comments"
                          :key="comment.timestamp + index"
                          small
                          color="primary"
                        >
                          <v-card color="darkbtn2">
                            <v-card-title>
                              <span class="text-base white--text">Comment</span>
                              <v-spacer></v-spacer>
                              <small class="text-xs white--text text-no-wrap">{{ unixToFormattedTimeMonthNumeric(comment.timestamp) }}</small>
                            </v-card-title>
                            <v-card-text class="pt-4">
                              <p class="mb-0 comment-lb">
                                {{ comment.comment }}
                              </p>

                              <!-- Divider -->
                              <v-divider class="my-4"></v-divider>

                              <!-- Person -->
                              <div class="d-flex justify-space-between align-center">
                                <!-- Avatar & Personal Info -->
                                <span class="d-flex align-bottom mt-2">
                                  <!-- <v-avatar
                                        size="40"
                                        class="me-2"
                                      >
                                        <v-img src="@/assets/images/avatars/2.png"></v-img>
                                      </v-avatar> -->
                                  <div class="d-flex flex-column">
                                    <p class="text--primary font-weight-semibold mb-0">{{ comment.commenter_nickname }} </p>
                                    <span> {{ getCommentRoleText(comment) }} </span>
                                  </div>
                                </span>

                                <!-- Person Actions -->
                                <div>
                                  <v-icon class="me-2">
                                    {{ icons.mdiMessageTextOutline }}
                                  </v-icon>
                                  <v-icon>{{ icons.mdiPhone }}</v-icon>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                        <v-timeline-item
                          v-if="item.description"
                          small
                          color="primary"
                        >
                          <v-card color="darkbtn3">
                            <v-card-title>
                              <span class="text-base white--text">Initial report</span>
                              <v-spacer></v-spacer>
                              <small class="text-xs white--text text-no-wrap">{{ unixToFormattedTimeMonthNumeric(item.timestamp) }}</small>
                            </v-card-title>
                            <v-card-text class="pt-4">
                              <p class="mb-1">
                                <span><b>Report description</b></span>
                              </p>
                              {{ item.description }}
                              <p class="mb-1">
                                <span><b>Proof URL(s):</b></span>
                              </p>
                              <p
                                v-for="(proof, index) in item.evidence"
                                :key="index"
                                class="mb-1"
                              >
                                <span>{{ index + 1 }}. <a
                                  :href="proof"
                                  target="_blank"
                                >{{ proof }}</a></span>
                              </p>

                              <!-- Divider -->
                              <v-divider class="my-4"></v-divider>

                              <!-- Person -->
                              <div class="d-flex justify-space-between align-center">
                                <!-- Avatar & Personal Info -->
                                <span class="d-flex align-bottom mt-2">
                                  <!-- <v-avatar
                                        size="40"
                                        class="me-2"
                                      >
                                        <v-img src="@/assets/images/avatars/2.png"></v-img>
                                      </v-avatar> -->
                                  <div class="d-flex flex-column">
                                    <p class="text--primary font-weight-semibold mb-0">{{ item.reporter_nickname }} </p>
                                  </div>
                                </span>

                                <!-- Person Actions -->
                                <div>
                                  <v-icon class="me-2">
                                    {{ icons.mdiMessageTextOutline }}
                                  </v-icon>
                                  <v-icon>{{ icons.mdiPhone }}</v-icon>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :disabled="!$store.getters[hasLoadedGetter](page)"
              :total-visible="0"
              :length="$store.getters[itemCountGetter]()"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { unixToFormattedTimeMonthNumeric } from '@core/utils'
import { getReportStatusLabel } from '@core/utils/report'
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagon,
  mdiOpenInNew,
  mdiReload,
} from '@mdi/js'
import Loading from '@/views/components/Loading.vue'
import axiosClient from '@/plugins/axios'

// eslint-disable-next-line object-curly-newline

export default {
  components: {
    Loading,
  },
  props: [
    'headers',
    'cardTitle',
    'hasLoadedGetter',
    'itemCountGetter',
    'itemGetter',
    'itemLoadGetter',
    'refreshEvent',
    'showId',
    'allowExpand',
    'allowManagement',
  ],
  data() {
    return {
      newCommentText: '',
      commentRules: [v => v.length <= 350 || 'Comment must be less than 350 characters'],
      page: 0,
      loading: false,
      isCommentDialogVisible: false,
      isResolveDialogVisible: false,
      isPunishmentIssued: false,
      offense: null,
      banLength: null,
      resolveComments: '',
      refreshInProgress: false,
    }
  },
  computed: {
    reports() {
      return !this.$store.getters[this.hasLoadedGetter](this.page) || this.refreshInProgress
        ? []
        : this.$store.getters[this.itemGetter](this.page)?.data ?? []
    },
  },
  watch: {
    page(val) {
      if (!this.$store.getters[this.hasLoadedGetter](val)) this.$store.dispatch(this.itemLoadGetter, { page: val })
    },
    reports(val) {
      if (this.page > 1 && this.$store.getters[this.hasLoadedGetter](this.page) && val.length <= 0) this.page -= 1
    },
    isCommentDialogVisible(val) {
      if (!val) {
        this.newCommentText = ''
      }
    },
    isResolveDialogVisible(val) {
      if (!val) {
        this.isPunishmentIssued = false
        this.offense = null
        this.queueBanLength = null
        this.resolveComments = ''
      }
    },
  },
  created() {
    this.page = 1
  },
  methods: {
    resolveReport(item) {
      this.loading = true
      axiosClient({
        url: `${this.$API}/report/${item.report_id}/resolve`,
        method: 'POST',
        data: {
          isPunishmentIssued: this.isPunishmentIssued,
          offense: this.offense,
          queueBanLength: this.banLength,
          comment: this.resolveComments,
          reportee_id: item.reportee_id,
        },
      })
        .then(result => {
          item.resolution = result.data
          this.$root.vtoast.show('Report was successfully resolved!')
          this.isResolveDialogVisible = false
          setTimeout(() => (this.loading = false), 1000)
        })
        .catch(error => {
          this.loading = false
          this.$root.vtoast.show(error.response.data)
        })
    },
    assignToMe(item) {
      this.loading = true
      axiosClient({
        url: `${this.$API}/report/${item.report_id}/assign`,
        method: 'POST',
        data: {
          reportee_id: item.reportee_id,
        },
      })
        .then(result => {
          this.$store.dispatch('report/reloadAssignedReportsPages')
          this.$store.dispatch('report/reloadUnassignedReportsPages')
          this.$root.vtoast.show('Report was successfully assigned!')
          setTimeout(() => (this.loading = false), 1000)
        })
        .catch(error => {
          this.loading = false
          if (error.response.status == 409) this.$store.dispatch('report/reloadUnassignedReportsPages')
          this.$root.vtoast.show(error.response.data)
        })
    },
    postComment(item) {
      this.loading = true
      this.isCommentDialogVisible = false
      axiosClient({
        url: `${this.$API}/report/${item.report_id}/comment`,
        method: 'POST',
        data: {
          comment: this.newCommentText,
          reportee_id: item.reportee_id,
        },
      })
        .then(result => {
          this.loading = false
          this.newCommentText = ''
          item.comments = result.data
          this.isCommentDialogVisible = false
          this.$root.vtoast.show('Comment succesfully made!')
        })
        .catch(error => {
          this.loading = false
          this.$root.vtoast.show(error.response.data)
        })
    },
    unassign(item) {
      this.loading = true
      axiosClient({
        url: `${this.$API}/report/${item.report_id}/unassign`,
        method: 'POST',
        data: {
          reportee_id: item.reportee_id,
        },
      })
        .then(result => {
          this.$store.dispatch('report/reloadAssignedReportsPages')
          this.$store.dispatch('report/reloadUnassignedReportsPages')
          this.$root.vtoast.show('Report was successfully unassigned!')
          setTimeout(() => (this.loading = false), 1000)
        })
        .catch(error => {
          this.loading = false
          this.$root.vtoast.show(error.response.data)
        })
    },
    getCommentRoleText(item) {
      if (item.comment_type == 'staff') return item.commenter_role
      if (item.comment_type == 'reporter') return 'Report creator'
      if (item.comment_type == 'reportee') return 'Report target'

      return ''
    },
    async refresh() {
      if (!this.refreshEvent) return
      this.refreshInProgress = true
      await this.$store.dispatch(this.refreshEvent)
      setTimeout(() => (this.refreshInProgress = false), 500)
    },
  },
  setup() {
    const colorCheckbox = ['primary', 'secondary', 'success', 'info', 'warning', 'error']

    return {
      colorCheckbox,
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiAlertOctagon,
        mdiOpenInNew,
        mdiReload,
      },
      unixToFormattedTimeMonthNumeric,
      getReportStatusLabel,
    }
  },
}
</script>
